<template>
    <div class="wrapper">
        <div class="notifications">
            <div class="notification-block" v-for="item in allNotifications" :key="item.id">
                <div class="information">
                    <div class="date">
                        <h3>{{ new Date(item.dateAdded).toLocaleDateString('ru-RU',  { year: 'numeric', month: 'long', day: 'numeric'})}}</h3>
                    </div>
                    <div class="header">
                        <ico v-if="item.priority === 'IMPORTANT'" name="icoError" color="#EC3323" size="30px"></ico>
                        <ico v-if="item.priority === 'SUCCESS'" name="icoCheckCircle" color="#12AE0E" size="30px"></ico>
                        <h3 v-html="JSON.parse(item.header.replaceAll(/\\n/gm, '<br>'))"></h3>
                    </div>
                    <p v-html="JSON.parse(item.content.replaceAll(/\\n/gm, '<br>'))"></p>
                </div>
                <div class="actions">
                    <router-link to="/profile/subscription"><button v-if="item.buttonIncluded" class="action-subscription btn btn-middle"> Продлить подписку</button></router-link>
                </div>
            </div>
        </div>   
    </div>
</template>

<script>
    import { mapGetters } from 'vuex/dist/vuex.common.js';

    export default {
        name: "ArchiveNotifications",
        computed: {
            ...mapGetters('notifications', ['allNotifications']),
        },
    }
        
</script>

<style lang="less" scoped>
    .wrapper{
        .notifications{
            .notification-block{
                width: 670px;
                display: flex;
                flex-direction: column;
                gap: 16px;
                border-bottom: 1px solid #EEEEEE;
                padding: 24px 0;

                .information{
                    font-size: 16px;

                    .header{
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        padding: 0;
                    }
                }

                .actions{
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    gap: 25px;
                    cursor: pointer;

                    .action-readed{
                        color: #7D919B;
                    }  
                }
            }
        }
    }
</style>
